import "../styles/globals.css";
import type { AppProps } from "next/app";
import WalletProvider from "../components/WalletProvider";
import CurrencyConverterProvider from "../components/CurrencyConverterProvider";
import ReactorProvider from "../components/ReactorProvider";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      retry: 0,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <ReactorProvider>
          <CurrencyConverterProvider>
            <Component {...pageProps} />
          </CurrencyConverterProvider>
        </ReactorProvider>
      </WalletProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
