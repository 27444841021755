import React from "react";
import { useQuery } from "react-query";

type CurrencyConverterType = {
  setCurrencyConverterActive: (active: boolean) => void;
  loading: boolean;
  ETHExchangeRate: number;
  BTCExchangeRate: number;
  converterError?: Response | Error;
};

type CoinbaseExchangeDataType = {
  data: {
    base: string;
    currency: string;
    amount: string;
  };
};

const CurrencyConverterContext = React.createContext<
  CurrencyConverterType | undefined
>({
  setCurrencyConverterActive: () => {},
  loading: false,
  ETHExchangeRate: 0,
  BTCExchangeRate: 0,
  converterError: undefined,
});

//TODO: Refactor this out of a provider, to use useQuery everywhere
const CurrencyConverterProvider: React.FunctionComponent = ({ children }) => {
  const [isActive, setCurrencyConverterActive] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [ETHExchangeRate, setETHExchangeRate] = React.useState<number>(0);
  const [converterError, setConverterError] = React.useState<
    Response | Error | undefined
  >(undefined);

  const pollingRate = Number(
    process.env.NEXT_PUBLIC_CURRENCY_EXCHANGE_POLLING_RATE,
  );

  const {
    data: BTCExchangeRate = 0,
    isLoading: btcLoading, //TODO: pass btcLoading and btcError into the final returned loading/error
    isError: btcError,
  } = useQuery({
    queryKey: ["btcprice"],
    queryFn: async () => {
      const response = await fetch(
        "https://api.coinbase.com/v2/prices/BTC-USD/buy",
      );
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
      const priceData: CoinbaseExchangeDataType = await response.json();
      return Number(priceData.data.amount);
    },
    refetchInterval: pollingRate,
    enabled: isActive,
  });

  //TODO: Do same with eth as btc
  const fetchExchangeData = (options: RequestInit) => {
    setLoading(true);
    fetch("https://api.coinbase.com/v2/prices/ETH-USD/buy", options)
      .then((response: Response) => {
        // Fetch will resolve responses even if it's a 4XX/5XX, hence the following:
        if (!response.ok) {
          setConverterError(response);
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((result: CoinbaseExchangeDataType) => {
        setETHExchangeRate(Number(result.data.amount));
        setLoading(false);
      })
      .catch((e: Error) => {
        // Fetch is cancelled if user navigates away, etc. (cleanup function in useEffect hook below)
        if (e.name === "AbortError") {
          console.log("Fetch successfully aborted.");
        } else {
          setLoading(false);
          setConverterError(e);
          console.error(e);
        }
      });
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchExchangeData({ signal });
    const exchangeRateInterval = setInterval(() => {
      if (!isActive) return;
      fetchExchangeData({ signal });
    }, Number(process.env.NEXT_PUBLIC_CURRENCY_EXCHANGE_POLLING_RATE));

    return () => {
      controller.abort();
      clearInterval(exchangeRateInterval);
    };
  }, [isActive]);

  const contextValue: CurrencyConverterType = React.useMemo(
    () => ({
      setCurrencyConverterActive,
      loading,
      ETHExchangeRate,
      BTCExchangeRate,
      converterError,
    }),
    [
      setCurrencyConverterActive,
      loading,
      ETHExchangeRate,
      BTCExchangeRate,
      converterError,
    ],
  );

  return (
    <CurrencyConverterContext.Provider value={contextValue}>
      {children}
    </CurrencyConverterContext.Provider>
  );
};

export default CurrencyConverterProvider;

export const useCurrencyConverter = () => {
  const currencyConverterContext = React.useContext(CurrencyConverterContext);
  if (currencyConverterContext === undefined) {
    throw new Error(
      "useCurrencyConverter must be used in a child of CurrencyConverterProvider",
    );
  }
  return currencyConverterContext;
};
