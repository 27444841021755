import EventEmitter from "events";
import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  getAccountInfo,
  getInventory,
  getWorkerData,
  getPoolConfiguration,
  jwtLogOut,
} from "../services/reactor/api";
import {
  InventoryResponse,
  WorkersResponse,
  jwtQueryResponse,
  CurrentPoolInfo,
  AccountResponse,
  VirtualMiner,
} from "../services/reactor/types";
import { WalletName } from "./WalletConnection/walletConfigurations";
import { useQuery } from "react-query";
import { GroupInfo, Worker } from "../services/reactor/types";

type WalletContextType = {
  initializing: boolean;
  accountAddress: string;
  email: string;
  walletName: WalletName;
  jwtToken: string;
  logOut: () => void;
  walletConnectError: boolean;
  resetError: () => void;
  walletConnected: boolean;
  hasTokens: boolean;
  tokens: number[];
  tokenCount: number;
  refreshBlockchain: () => Promise<void>;
  refreshJWT: () => Promise<void>;
  refreshMiners: (wallet?: string) => Promise<void>;
  refreshPool: () => Promise<void>;
  refreshAccount: () => Promise<void>;
  setJWT: (jwt: string, wallet: string, expiry: number) => Promise<void>;
  poolName: string | undefined;
  poolAccount: string | undefined;
  inventory: InventoryResponse;
  successful_queries: Worker[];
  group_info: GroupInfo;
  trial_miner_activated: boolean;
  is_admin: boolean;
  virtual_miners: VirtualMiner[];
  is_agreement_signed: boolean;
  minersLoading: boolean;
};

const WalletContext = React.createContext<WalletContextType | undefined>({
  initializing: false,
  accountAddress: "",
  email: "",
  walletName: "Connect with Lightning",
  jwtToken: "",
  logOut: () => {},
  walletConnectError: false,
  resetError: () => {},
  walletConnected: false,
  hasTokens: false,
  tokens: [],
  tokenCount: 0,
  refreshBlockchain: async () => undefined,
  refreshJWT: async () => undefined,
  refreshMiners: async () => undefined,
  refreshPool: async () => undefined,
  refreshAccount: async () => undefined,
  setJWT: async () => undefined,
  poolName: undefined,
  poolAccount: undefined,
  inventory: {
    available: false,
    available_terahashes: 0,

    per_miner_hashrate: 0,
    per_miner_duration: 0,
    per_miner_price: 0,

    trial_available: false,
    trial_miner_hashrate: 0,
    trial_miner_duration: 0,

    stripe_price_id: "",
  } as InventoryResponse,
  successful_queries: [],
  group_info: {
    active_count: 0,
    count: 0,
    hashrate: 0,
    hashrate_1d: 0,
    hashrate_30m: 0,
    triggering_alerts: 0,
  },
  trial_miner_activated: true,
  is_admin: false,
  virtual_miners: [],
  is_agreement_signed: false,
  minersLoading: false,
});

const WalletProvider: React.FunctionComponent = ({ children }) => {
  const [walletName, setWalletName] = useState<WalletName>(
    "Connect with Lightning",
  );

  const [newJWT, setNewJWT] = useState("");
  const [newExpiry, setNewExpiry] = useState(0);

  const router = useRouter();

  const [walletConnectError, setWalletConnectError] = useState<boolean>(false);

  const locallyStoreNewJwt = async (
    jwt: string,
    expiry: number,
    wallet: string,
  ) => {
    const storedData = { jwtToken: jwt, expiryTime: expiry, wallet: wallet };
    localStorage.removeItem("userData");
    localStorage.setItem("userData", JSON.stringify(storedData));
  };

  interface LocalJwtResponse {
    jwtToken: string;
    expiryTime: number;
    wallet: string;
  }

  const checkLocalJwt = async (): Promise<LocalJwtResponse> => {
    const data = localStorage.getItem("userData");
    if (data) {
      const storedData = JSON.parse(data);
      if (
        !storedData.jwtToken ||
        !storedData.expiryTime ||
        storedData.expiryTime < new Date().getTime() / 1000
      ) {
        localStorage.removeItem("userData");
        return { jwtToken: "", expiryTime: 0, wallet: "" };
      } else return storedData;
    } else {
      return { jwtToken: "", expiryTime: 0, wallet: "" };
    }
  };

  const logOut = async () => {
    console.log("Logging out");
    localStorage.removeItem("userData");
    await jwtLogOut({ jwtToken });
    await refetchJWT();
  };

  const {
    isLoading: jwtLoading,
    data: { jwtToken } = { jwtToken: "" },
    refetch: refetchJWT,
    error: jwtError,
  } = useQuery({
    queryKey: ["jwt", walletName, newJWT, newExpiry],
    queryFn: async (): Promise<jwtQueryResponse> => {
      /*
      If newJWT and newExpiry exist, the current session will be logged out and replaced
      In theory these should never have values if the user is logged in
      */
      if (
        !walletConnected /* only run if wallet isnt already connected */ &&
        newJWT &&
        newExpiry
      ) {
        locallyStoreNewJwt(newJWT, newExpiry, walletName);
        return { jwtToken: newJWT };
      } else {
        /* 
      otherwise check stored data for already made token
      check stored data for existing, not expired jwt 
      */
        const storedData = await checkLocalJwt();
        if (storedData.expiryTime > new Date().getTime() / 1000) {
          if (storedData.wallet) {
            setWalletName(storedData.wallet);
          } else {
            logOut();
          }
          return { jwtToken: storedData.jwtToken };
        } // else returned logged out user
        else return { jwtToken: "" };
      }
    },
  });

  const {
    isLoading: accountInfoLoading,
    data: {
      account_id,
      linking_key,
      email,
      trial_miner_activated,
      is_admin,
      virtual_miners,
      is_agreement_signed,
    } = {
      account_id: "",
      linking_key: "",
      email: "",
      trial_miner_activated: true,
      is_admin: false,
      virtual_miners: [],
      is_agreement_signed: false,
    },
    refetch: refetchAccount,
    error: accountError,
  } = useQuery({
    queryKey: ["account", jwtToken, walletName],
    queryFn: async (): Promise<AccountResponse> => {
      const emptyReturn = {
        account_id: "",
        linking_key: "",
        email: "",
        trial_miner_activated: true,
        is_admin: false,
        virtual_miners: [],
        is_agreement_signed: false,
      };

      if (!jwtToken) return emptyReturn;
      if (!!jwtToken) {
        try {
          const a = await getAccountInfo(jwtToken);
          return {
            account_id: a.id,
            linking_key: a.linking_key,
            email: a.email,
            trial_miner_activated: a.is_admin ? false : a.trial_miner_activated,
            is_admin: a.is_admin,
            virtual_miners: a.virtual_miners,
            is_agreement_signed: a.is_agreement_signed || a.is_admin,
          };
        } catch {
          await logOut();
          return emptyReturn;
        }
      }
      return emptyReturn;
    },
    enabled: !!jwtToken,
  });
  const {
    isLoading: addressLoading,
    data: { accountAddress, walletConnected } = {
      accountAddress: "",
      walletConnected: false,
    },
    refetch: refetchAddress,
  } = useQuery({
    queryKey: ["address", account_id],
    queryFn: async () => {
      const walletConnected = !!account_id && !!jwtToken;
      if (walletConnected)
        return { accountAddress: account_id, walletConnected };
      return {
        accountAddress: walletConnected ? account_id : "",
        walletConnected,
      };
    },
    enabled: !jwtLoading && !accountInfoLoading,
  });

  const {
    isLoading: minersLoading,
    data: { successful_queries, group_info } = {
      successful_queries: [],
      group_info: {
        active_count: 0,
        count: 0,
        hashrate: 0,
        hashrate_1d: 0,
        hashrate_30m: 0,
        triggering_alerts: 0,
      },
    },
    refetch: refetchMiners,
  } = useQuery({
    queryKey: ["miners", accountAddress, walletConnected],
    queryFn: async (): Promise<WorkersResponse> => {
      return await getWorkerData(accountAddress);
    },
    enabled: walletConnected,
  });

  const {
    isLoading: tokensLoading,
    data: tokens = [],
    refetch: refetchTokens,
  } = useQuery({
    queryKey: ["tokens", accountAddress, successful_queries],
    queryFn: async () => {
      const nftIds: number[] = successful_queries
        .filter((worker) => worker.nft_id !== undefined)
        .map((worker) => worker.nft_id);
      return nftIds;
    },
    enabled: walletConnected,
  });

  const {
    isLoading: inventoryLoading,
    data: inventory = {
      available: false,
      available_terahashes: 0,

      per_miner_hashrate: 0,
      per_miner_duration: 0,
      per_miner_price: 0,

      trial_available: false,
      trial_miner_hashrate: 0,
      trial_miner_duration: 0,

      stripe_price_id: "",
    },
    refetch: refetchInventory,
  } = useQuery<InventoryResponse>({
    queryKey: ["inventory"],
    queryFn: async () => {
      return await getInventory();
    },
  });

  const {
    isLoading: poolConfigLoading,
    data: { poolName, poolAccount } = {
      poolName: undefined,
      poolAccount: undefined,
    },
    refetch: refetchPoolConfig,
  } = useQuery({
    queryKey: ["pools", accountAddress, walletConnected],
    queryFn: async (): Promise<CurrentPoolInfo> => {
      try {
        const out = await getPoolConfiguration(accountAddress);
        console.log("Pool Config :>> ", out);
        return {
          poolName: out.pool_selection,
          poolAccount: out.pool_account,
        };
      } catch (e) {
        return { poolName: undefined, poolAccount: undefined };
      }
    },
    enabled: walletConnected,
  });

  useEffect(() => {
    if (walletConnected && newJWT && newExpiry) {
      setNewJWT("");
      setNewExpiry(0);
    }
  }, [walletConnected, newExpiry, newJWT]);

  const refreshPool = async () => {
    await refetchPoolConfig();
  };

  const refreshBlockchain = async () => {
    await Promise.all([refetchTokens()]);
  };

  const refreshMiners = async () => {
    await Promise.all([refetchMiners()]);
  };

  const refreshAccount = async () => {
    await Promise.all([refetchAccount()]);
  };

  const refreshJWT = async () => {
    Promise.all([refetchJWT(), refetchAddress()]);
  };

  const setJWT = async (jwt: string, wallet: string, expiry: number) => {
    setNewJWT(jwt);
    setWalletName(wallet);
    setNewExpiry(expiry);
  };

  const [tokenCount, setTokenCount] = useState(0);
  const [hasTokens, setHasTokens] = useState(false);

  useEffect(() => {
    if (tokens && tokens.length > 0) {
      setTokenCount(tokens.length);
      setHasTokens(true);
      return;
    } else if (poolAccount !== "unspecified" && poolAccount !== undefined) {
      setTokenCount(1);
      setHasTokens(true);
      return;
    }
  }, [tokenCount, tokens, poolAccount, poolConfigLoading, virtual_miners]);

  const initializing =
    inventoryLoading || addressLoading || jwtLoading || accountInfoLoading;

  const resetError = () => {
    setWalletConnectError(false);
  };

  /**
   * (end possible ContractProvider methods)
   */

  const contextValue: WalletContextType = {
    initializing,
    accountAddress,
    email,
    walletName,
    jwtToken,
    logOut,
    walletConnectError,
    resetError,
    walletConnected,
    hasTokens,
    tokens,
    tokenCount,
    refreshBlockchain,
    refreshJWT,
    refreshMiners,
    refreshPool,
    refreshAccount,
    setJWT,
    poolName,
    poolAccount,
    inventory,
    successful_queries,
    group_info,
    trial_miner_activated,
    is_admin,
    virtual_miners,
    is_agreement_signed,
    minersLoading,
  };

  return (
    <WalletContext.Provider value={contextValue}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;

export const useWallet = () => {
  const walletContext = useContext(WalletContext);
  if (walletContext === undefined) {
    throw new Error("useWallet must be used in a child of WalletProvider");
  }
  return walletContext;
};
