import {
  PoolResponse,
  ReactorResponse,
  WorkersResponse,
  SigningUrlResponse,
  WhitelistResponse,
  AddWhitelistResponse,
  PoolConfigResponse,
  InventoryResponse,
  AccredationResponse,
  AlbyResponse,
  FreeMintResponse,
  jwtResponse,
  updatePoolConfigResponse,
  AccountResponse,
  ZendeskResponse,
  BaseParams,
  FetchOptions,
  FormData,
  AuthorizationParams,
  FreeMinerMint,
  FreePoolParams,
  EmailParams,
  TicketData,
  AccountApiResponse,
} from "./types";
import { setPoolSigningMessage } from "./lang";

type AdminMiner = {
  startTime: number; // unix millis
  hashrate: number; // number, TH
  duration: number; // seconds
  accountId: string;
  quantity: number;
} & AuthorizationParams;

export const adminMinerCreation = async (params: AdminMiner) => {
  const { startTime, hashrate, duration, accountId, quantity, jwtToken } =
    params;

  const options: FetchOptions = {
    method: "PUT",
    signature: jwtToken,
  };

  return _fetch(
    `/virtualminer/admin?start_time=${
      startTime / 1000
    }&quantity=${quantity}&hashrate=${hashrate}&account_id=${accountId}&duration=${duration}`,
    options,
  ) as Promise<FreeMintResponse>;
};

type UserParams = {
  email: string;
  password: string;
};

type RegistrationResponse = {
  account_id: string;
  active: boolean;
  email: string;
  email_confirmed_at?: boolean;
  id: number;
};

// register a new user
export const registerUser = async (params: UserParams) => {
  const url = "/pwauth/register";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    requestData: params,
  };

  return _fetch(url, options) as Promise<RegistrationResponse>;
};

// log in with email and password
export const LogIn = async (params: UserParams) => {
  const url = "/pwauth/login";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    requestData: params,
  };

  return _fetch(url, options) as Promise<jwtResponse>;
};

// re request verification email be sent
export const requestEmailVerification = async (email: string) => {
  const url = "/pwauth/email";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    requestData: { email },
  };

  return _fetch(url, options) as Promise<string>;
};

// send an email to reset the users password
export const resetPasswordRequest = async (email: string) => {
  const url = "/pwauth/reset/initiate";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    requestData: { email },
  };

  return _fetch(url, options) as Promise<string>;
};

type ResetPasswordParams = {
  password: string;
  token: string;
};

// reset the users password
export const resetPassword = async (params: ResetPasswordParams) => {
  const url = "/pwauth/reset/confirm";
  const { password, token } = params;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    requestData: { password, token },
  };

  return _fetch(url, options) as Promise<string>;
};

// verify the user owns the email by passing the token to the backend
export const verifyUser = async (token: string) => {
  return _fetch(`/pwauth/verify/${token}`) as Promise<string>;
};

export const createZendeskTicket = async (ticketData: TicketData) => {
  const url = "/zendesk/ticket";
  const options: FetchOptions = {
    method: "POST",
    requestData: ticketData,
  };

  return _fetch(url, options) as Promise<ZendeskResponse>;
};

export const getLnurlKeys = async () => {
  return _fetch(`/lnauth`) as Promise<AlbyResponse>;
};

export const getJwtToken = async (
  method: string,
  k1: string,
  secret: string,
) => {
  return _fetch(
    `/lnauth/token?${method}=${k1}&secret=${secret}`,
  ) as Promise<jwtResponse>;
};

export const jwtLogOut = async (params: AuthorizationParams) => {
  const options: FetchOptions = {
    method: "DELETE",
    signature: params.jwtToken,
  };

  try {
    await _fetch(`/session`, options);
  } catch (error) {}
};

type AdminAccountRequest = {
  email?: string;
  account_id?: string;
  virtual_miner?: number;
} & AuthorizationParams;

export const getAnyAccountInfo = async (params: AdminAccountRequest) => {
  const { email, account_id, virtual_miner, jwtToken } = params;
  let endpoint = "";

  if (!!email) {
    endpoint = `/account?email=${email}`;
  } else if (!!account_id) {
    endpoint = `/account?account_id=${account_id}`;
  } else if (!!virtual_miner) {
    endpoint = `/account?virtual_miner=${virtual_miner}`;
  } else {
    return [
      {
        account_id: "",
        linking_key: "",
        email: "",
        trial_miner_activated: false,
        is_admin: false,
        virtual_miners: [],
        is_agreement_signed: false,
      },
    ];
  }

  const response = (await _fetch(endpoint, {
    method: "GET",
    signature: jwtToken,
  })) as AccountApiResponse[];

  const mappedAccounts = response.map((account) => ({
    account_id: account.id,
    linking_key: account.linking_key,
    email: account.email,
    trial_miner_activated: account.trial_miner_activated,
    is_admin: account.is_admin,
    virtual_miners: account.virtual_miners,
    is_agreement_signed: account.is_agreement_signed,
  }));

  return mappedAccounts;
};

export const getAccountInfo = async (jwtToken: string) => {
  return (await _fetch(`/account`, {
    method: "GET",
    signature: jwtToken,
  })) as Promise<AccountApiResponse>;
};

export const setUserEmail = async (params: EmailParams) => {
  const { jwtToken, email } = params;
  return _fetch(`/account`, {
    method: "PATCH",
    signature: jwtToken,
    requestData: { email },
  }) as Promise<AccountResponse>;
};

export const mintFreeMiner = async (params: FreeMinerMint) => {
  const { jwtToken } = params;
  const options: FetchOptions = {
    method: "POST",
    signature: jwtToken,
  };
  return _fetch(`/virtualminer/trial`, options) as Promise<FreeMintResponse>;
};

export const updatePoolConfig = async (params: FreePoolParams) => {
  const { poolChoice, poolWorkerName, jwtToken } = params;
  const options: FetchOptions = {
    method: "PATCH",
    signature: jwtToken,
  };

  return _fetch(
    `/account/config?pool_id=${poolChoice.id}&pool_account=${poolWorkerName}`,
    options,
  ) as Promise<updatePoolConfigResponse>;
};

export const sendAccredationData = async (userData: FormData) => {
  const options: FetchOptions = {
    method: "POST",
    requestData: userData,
  };
  return _fetch(`/verification`, options) as Promise<AccredationResponse>;
};

const getPools = async () => {
  return _fetch("/pools") as Promise<PoolResponse>;
};

export const getPoolOptions = async () => {
  const { pools } = await getPools();
  return Object.entries(pools).map(([k, v]) => {
    return {
      id: k,
      name: v,
    };
  });
};

export const getSigningUrl = async (props: AuthorizationParams) => {
  const { jwtToken } = props;
  const ret = _fetch(`/signing_url`, {
    method: "POST",
    signature: jwtToken,
  });

  return ret as Promise<SigningUrlResponse>;
};

type CheckoutProps = {
  quantity: number;
  hashrate: number;
  duration: number;
  successUrl: string;
  cancelUrl: string;
} & AuthorizationParams;

type CheckoutResponse = {
  status: string;
  url: URL;
};

export const createCheckoutSession = async (props: CheckoutProps) => {
  const options = {
    method: "POST",
    signature: props.jwtToken,
    requestData: {
      success_url: props.successUrl,
      cancel_url: props.cancelUrl,

      quantity: props.quantity,
      hashrate: props.hashrate,
      duration: props.duration,
    },
  };

  return _fetch("/checkoutsession", options) as Promise<CheckoutResponse>;
};

export const getInventory = async () => {
  return _fetch(`/inventory`) as Promise<InventoryResponse>;
};

export const getWorkerData = async (accountAddress: string) => {
  return (await _fetch(`/workers/${accountAddress}`)) as WorkersResponse;
};

export const getPoolConfiguration = (address: string) => {
  return _fetch(`/configurations/${address}`) as Promise<PoolConfigResponse>;
};

const _fetch = async (
  endpoint: string,
  options: FetchOptions = {},
): Promise<ReactorResponse> => {
  const { signature, requestData, method, signal } = options;
  const headers = new Headers();
  let apiUrl = "";
  if (process.env.NEXT_PUBLIC_NODE_ENV === "development") {
    // local/env API
    apiUrl =
      process.env.NEXT_PUBLIC_REACTOR_SERVER_URL || "http://localhost:5001";
  } else {
    // public API
    if (window.location.hostname.endsWith("minectl.com")) {
      apiUrl = "https://" + window.location.host.replace(".app.", ".api.");
    } else {
      apiUrl =
        "https://" +
        window.location.host
          .replace("reactor.mine.app", "api.reactor.mine.app")
          .replace("app.reactor.market", "api.reactor.mine.app")
          .replace("app.reactor.xyz", "api.reactor.mine.app");
    }
  }
  if (requestData) headers.append("Content-Type", "application/json");
  if (signature) headers.append("Authorization", `Bearer ${signature}`);
  // headers.append("mode", "no-cors");
  const url = `${apiUrl}${endpoint}`;
  const _options = {
    method: method ? method : requestData ? "PUT" : "GET", // TODO: Refactor this logic
    headers,
    body: requestData && JSON.stringify(requestData),
    signal,
  };

  return fetch(url, _options).then(async (responseData: Response) => {
    if (responseData.ok) return responseData.json();

    // Get the JSON response body if it's a 4XX/5XX error
    const errorData = await responseData.json();

    // Throw an error object with both status and message
    const error = {
      status: responseData.status,
      message: errorData.message || responseData.statusText,
    };
    console.error(error);
    throw error;
  });
};
