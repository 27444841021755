import React, { useContext, useEffect, useState } from "react";
import { Nonce, TargetPool } from "../services/reactor/types";
import { useWallet } from "./WalletProvider";

type ReactorContextType = {
  nonce: Nonce | undefined;
  poolConfig: TargetPool | undefined;
  setNonce: React.Dispatch<React.SetStateAction<Nonce | undefined>>;
  setPoolConfig: React.Dispatch<React.SetStateAction<TargetPool | undefined>>;
};

const ReactorContext = React.createContext<ReactorContextType | undefined>({
  nonce: undefined,
  poolConfig: undefined,
  setNonce: () => {},
  setPoolConfig: () => {},
});

const ReactorProvider: React.FunctionComponent = ({ children }) => {
  const [nonce, setNonce] = useState<Nonce>();
  const [poolConfig, setPoolConfig] = useState<TargetPool>();
  const { initializing, accountAddress } = useWallet();

  const contextValue: ReactorContextType = React.useMemo(
    () => ({
      nonce,
      poolConfig,
      setNonce,
      setPoolConfig,
    }),
    [nonce, poolConfig],
  );

  return (
    <ReactorContext.Provider value={contextValue}>
      {children}
    </ReactorContext.Provider>
  );
};

export default ReactorProvider;

export const useReactor = () => {
  const c = useContext(ReactorContext);
  if (c === undefined) {
    throw new Error("useReactor must be used in a child of ReactorProvider");
  }
  return c;
};
